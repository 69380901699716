<template>
<div class="panel has-text-weight-semibold">
    <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
        <div class="columns is-mobile">
            <div class="column is-size-11 has-text-centered">COMMISSION REPORT</div>
            <div class="column has-text-right is-1">
                <!-- <b-button class="is-dark is-small" @click="generatePDF">Print</b-button> -->
                <!-- <b-button v-if="getSetting('showCommission') && getSettingUserAuthorized('settingsAuthorizedEmails')" class="is-dark is-small is-hidden-mobile" @click="togglePDFDialog">Print</b-button> -->
                <b-button v-if="getSetting('showCommission') && getSettingUserAuthorized('commissionAuthorizedEmails')"  title="Print" type="is-dark" outlined class="is-small is-hidden-mobile" @click="togglePDFDialog">
                    <i class="fas fa-print"></i>
                </b-button>
            </div>
        </div>
    </div>
    <div class="panel has-text-weight-semibold" v-if="getSetting('showCommission') && getSettingUserAuthorized('settingsAuthorizedEmails')">
        <b-loading v-model="isLoading" >
            <b-icon
                pack="fas"
                icon="sync-alt"
                size="is-large"
                custom-class="fa-spin">
            </b-icon>                
        </b-loading>        
        <div class="columns">
            <div class="column">
                <!-- <date-range-selector
                    :dateRange="salesDateRange"
                    @set-date-range="setDateRange"
                    >
                </date-range-selector> -->
                <b-field  grouped>
                        <b-datepicker
                            custom-class="month-date-selector"
                            ref="monthSelector"
                            type="month"
                            v-model="monthSelected" 
                            :mobile-native="false"
                            icon="calendar"
                            :date-formatter="monthFormatter"
                            >
                        </b-datepicker>
                        <!-- <b-button
                            @click="$refs.monthSelector.toggle()"
                            :disabled="isEditing"
                            icon-left="calendar"
                            type="is-primary" /> -->
                    </b-field>
            </div>
            <div class="column">

            </div>
        </div>

        <div id="ReportContent">
            <div class="columns" v-if="showStoreName">
                <div class="column">
                    <div class="columns has-text-centered has-text-weight-bold">
                    <div class="column is-size-2 is-12">{{ getActiveStore.storeName }}</div>
                </div>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <div class="columns has-text-centered has-text-weight-bold">
                    <!-- <div class="column is-size-2 is-12">{{ monthName }} {{ month.getFullYear() }}</div> -->
                    <div class="column is-size-5 is-12">Period: {{ salesDateRange[0] | formatDate }} to {{ salesDateRange[1] | formatDate }}</div>
                </div>
                </div>
            </div>
            <div class="columns">
                <div class="column is-3"></div>
                <div class="column is-6">
                    <div class="is-size-4 has-text-weight-bold has-text-centered has-background-grey-lighter">
                        COMMISSION REPORT
                    </div>
                    <br>
                    <div class="panel">
                        <div class="panel-block">
                        <section class="control">
                        <div class="columns is-mobile bottom-bordered-row total-row">
                            <div class="column has-text-centered">
                                {{ monthSelected | formatDateMonthYear }} Merchandise Sales
                            </div>
                        </div>
                        <div class="columns is-mobile bottom-bordered-row" v-bind:key="dept.storeMapping" v-for="dept in sortArrayByProp(deptSales, 'storeMapping', 'asc')">
                            <div class="column is-6 has-text-left">
                                {{getDeptMappingName(dept.storeMapping) }}
                            </div>
                            <div class="column is-2 has-text-right">
                                {{ (totalGrossRevenue ? (dept.netSalesValue / totalGrossRevenue * 100) : 0).toFixed(2) }}%
                            </div>
                            <div class="column is-4 has-text-right">
                                {{ dept.netSalesValue | formatCurrency}}
                            </div>
                        </div>


                        <div class="columns is-mobile total-row">
                            <div class="column is-6 has-text-left">
                                Total Merchandise Sales
                            </div>
                            <div class="column is-2 has-text-right">
                                {{ (totalGrossRevenue ? (totalMerchandiseRevenue / totalGrossRevenue * 100) : 0).toFixed(2) }}%
                            </div>
                            <div class="column is-4 has-text-right">
                                {{ totalMerchandiseRevenue | formatCurrency}}
                            </div>
                        </div>
                        <br>
                        <div class="columns is-mobile bottom-bordered-row">
                            <div class="column is-6">
                                Store Commission - {{ monthSelected | formatDateMonthYear }}
                            </div>
                            <div numeric class="column is-2 has-text-right">
                                {{ settingsValues.commissionStorePercentage.value  }}%
                            </div>
                            <div numeric class="column is-4 has-text-right">
                                {{ storeSalesCommission | formatCurrency }}
                            </div>
                        </div>
                        </section>
                        </div>
                    
                    </div>
                </div>
                <div class="column is-3"></div>
            </div>

            <br>

            <div class="columns">
                <div class="column is-3"></div>
                <div class="column is-6">
                    <div class="panel-block" v-if="totalPrevGrossRevenue != 0.00 && previousYearMonthlySales == 0">
                        <section class="control">
                        <div class="columns is-mobile bottom-bordered-row total-row">
                            <div class="column has-text-centered">
                                {{ prevYearDate | formatDateMonthYear }} Merchandise Sales
                            </div>
                        </div>
                        <div class="columns is-mobile bottom-bordered-row" v-bind:key="dept.storeMapping" v-for="dept in sortArrayByProp(deptSales, 'storeMapping', 'asc')">
                            <div class="column is-6 has-text-left">
                                {{getDeptMappingName(dept.storeMapping) }}
                            </div>
                            <div class="column is-2 has-text-right">
                                {{ (dept.prevYearSales / totalPrevGrossRevenue * 100).toFixed(2) }}%
                            </div>
                            <div class="column is-4 has-text-right">
                                {{ dept.prevYearSales | formatCurrency}}
                            </div>
                        </div>

                        <div class="columns is-mobile total-row">
                            <div class="column is-6 has-text-left">
                                Total Merchandise Sales
                            </div>
                            <div class="column is-2 has-text-right">
                                {{ (totalPrevGrossRevenue / totalPrevGrossRevenue * 100).toFixed(2) }}%
                            </div>
                            <div class="column is-4 has-text-right">
                                {{ totalPrevGrossRevenue | formatCurrency}}
                            </div>
                        </div>
                        
                        <br>

                        <div class="columns is-mobile total-row"  :class="{'has-text-danger': totalMerchandiseRevenue < totalPrevGrossRevenue }">
                            <div class="column is-6 has-text-left">
                                Sales <span v-if="totalMerchandiseRevenue > totalPrevGrossRevenue">Increase</span><span v-else>Decrease</span>
                            </div>
                            <div class="column is-2 has-text-right">
                                {{ ((totalMerchandiseRevenue - totalPrevGrossRevenue) / totalMerchandiseRevenue * 100).toFixed(2) }}%
                            </div>
                            <div class="column is-4 has-text-right">
                                {{ (totalMerchandiseRevenue - totalPrevGrossRevenue) | formatCurrency}}
                            </div>
                        </div>

                        <br>

                        <div class="columns is-mobile bottom-bordered-row">
                            <div class="column is-6">
                                Store Bonus Commission 
                            </div>
                            <div numeric class="column is-2 has-text-right">
                                {{ settingsValues.commissionSalesIncreasePercentage.value }}%
                            </div>
                            <div numeric class="column is-4 has-text-right">
                                {{ salesIncreaseCommission | formatCurrency }}
                            </div>
                        </div>
                        </section>
                    </div>
                    <div class="panel-block" v-if="previousYearMonthlySales != 0">
                        <section class="control">
                            <div class="columns is-mobile bottom-bordered-row total-row">
                                <div class="column has-text-centered">
                                    {{ prevYearDate | formatDateMonthYear }} Merchandise Sales
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column is-6 has-text-left">
                                    Total Merchandise Sales *
                                </div>
                                <div class="column is-2 has-text-right">
                                    
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ previousYearMonthlySales | formatCurrency}}
                                </div>
                            </div>
                            <div class="columns is-mobile total-row"  :class="{'has-text-danger': totalMerchandiseRevenue < totalPrevGrossRevenue }">
                                <div class="column is-6 has-text-left">
                                    Sales <span v-if="totalMerchandiseRevenue > totalPrevGrossRevenue">Increase</span><span v-else>Decrease</span>
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ ((totalMerchandiseRevenue - totalPrevGrossRevenue) / totalMerchandiseRevenue * 100).toFixed(2) }}%
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ totalSalesIncrease | formatCurrency}}
                                </div>
                            </div>

                            <br>

                            <div class="columns is-mobile bottom-bordered-row">
                                <div class="column is-6">
                                    Store Bonus Commission 
                                </div>
                                <div numeric class="column is-2 has-text-right">
                                    {{ settingsValues.commissionSalesIncreasePercentage.value }}%
                                </div>
                                <div numeric class="column is-4 has-text-right">
                                    {{ salesIncreaseCommission | formatCurrency }}
                                </div>
                            </div>

                        </section>
                    </div>


                    <div v-if="totalPrevGrossRevenue == 0.00 && previousYearMonthlySales == 0">*** PREVIOUS YEAR DATA NOT AVAILABLE ***</div>
                    
                </div>
                <div class="column is-3"></div>

            </div>

            <br>

            <div class="columns">
                <div class="column is-3"></div>
                <div class="column is-6">
                    <div class="panel">
                        <div class="panel-block">
                        <section class="control">
                        <div class="columns is-mobile bottom-bordered-row total-row">
                            <div class="column has-text-centered">
                                Commission
                            </div>
                        </div>
                        <div class="columns is-mobile bottom-bordered-row">
                            <div class="column is-6">
                                Store Commission - {{ monthSelected | formatDateMonthYear }}
                            </div>
                            <div numeric class="column is-2 has-text-right">
                                {{ settingsValues.commissionStorePercentage.value  }}%
                            </div>
                            <div numeric class="column is-4 has-text-right">
                                {{ storeSalesCommission | formatCurrency }}
                            </div>
                        </div>
                        <div class="columns is-mobile bottom-bordered-row">
                            <div class="column is-6">
                                Store Bonus Commission 
                            </div>
                            <div numeric class="column is-2 has-text-right">
                                {{ settingsValues.commissionSalesIncreasePercentage.value }}%
                            </div>
                            <div numeric class="column is-4 has-text-right">
                                {{ salesIncreaseCommission | formatCurrency }}
                            </div>
                        </div>
                        <div class="columns is-mobile bottom-bordered-row">
                            <div class="column is-6">
                                Total Store Commission 
                            </div>
                            <div numeric class="column is-2 has-text-right">
                                <!-- {{ (totalMerchandiseRevenue / (storeSalesCommission + salesIncreaseCommission))   }}% -->
                            </div>
                            <div numeric class="column is-4 has-text-right">
                                {{ totalStoreCommission | formatCurrency }}
                            </div>
                        </div>
                        <br>
                        <div class="columns is-mobile bottom-bordered-row">
                            <div class="column is-6">
                                Manager Commission 
                            </div>
                            <div numeric class="column is-2 has-text-right">
                                {{ settingsValues.commissionManagerPercentage.value  }}%
                            </div>
                            <div numeric class="column is-4 has-text-right">
                                {{ managerCommission | formatCurrency }}
                            </div>
                        </div>
                        <div class="columns is-mobile bottom-bordered-row">
                            <div class="column is-6">
                                Assistant Manager Commission 
                            </div>
                            <div numeric class="column is-2 has-text-right">
                                {{ settingsValues.commissionAssistantManagerPercentage.value  }}%
                            </div>
                            <div numeric class="column is-4 has-text-right">
                                {{ assistantManagerCommission | formatCurrency }}
                            </div>
                        </div>
                        </section>
                        </div>
                    </div>
                </div>
            </div>

            <commission-pdf
                ref="pdfReport"
                :isShowPDFDialog="isShowPDFDialog"
                :dateRange="salesDateRange"
                :deptSales="deptSales"
                :totalPrevGrossRevenue="totalPrevGrossRevenue"
                :previousYearMonthlySales="previousYearMonthlySales"
                @close-pdf-dialog="togglePDFDialog"
            >
            </commission-pdf>
        </div>
    </div>
    <not-authorized v-else></not-authorized>
</div>
</template>

<script>

import UserStore from "../../../store/UserStore"
import moment from 'moment'
import axios from 'axios'
// import DateRangeSelector from '../../../components/app/DateRangeSelector'
import CommissionPdf from './CommissionPDF.vue'
import NotAuthorized from './../../../components/app/NotAuthorized.vue'

export default {

    components: {
        // DateRangeSelector,
        CommissionPdf,
        NotAuthorized
    },

    metaInfo: {
        title: 'Commission Summary'
    },

    data() {
        return {
            settingsValues: UserStore.getters.getStoreSettingsValues,
            showStoreName: false,
            isShowPDFDialog: false,
            isLoading: true,
            isFullPage: true,
            monthSelected: moment(this.monthSelected).subtract(1, 'months').startOf('month').toDate(),
            salesDateRange: [],
            user: [],
            endDate: new Date().toISOString().split('T')[0],
            minDate: new Date(2016, 8, 1),
            maxDate: new Date(),
            startDate: new Date(),
            month: new Date(),
            deptMappingsRevenue: [],
            profitAndLossData: {totalFuelVolumePurchased: 0.00},
            prevYearProfitAndLossData: {totalFuelVolumePurchased: 0.00},
            totalPrevGrossRevenue: 0.00,
            totalPrevMerchandiseRevenue: 0.00,
            prevDeptMappingsRevenue: 0.00,
            previousYearMonthlySalesItemId: null,
            previousYearMonthlySales: 0,
            prevYearDate: moment(this.monthSelected).subtract(1, 'years').startOf('month').toDate(),
            summaryNumbers: {},
            totalFuelRevenue: 0.00,
            totalFuelCost: 0.00,
            totalFuelVolume: 0.00,
            totalMerchandiseRevenue: 0.00,
            totalOtherIncome: 0.00,
            totalGrossRevenue: 0.00,
            totalFuelProfit: 0.00,
            totalMerchandiseCost: 0.00,
            totalMerchandiseProfit: 0.00,
            totalCostOfGoodsSold: 0.00,
            totalProfit: 0.00,
            totalOperatingExpense: 0.00,
            fuelProfitPerGallon: 0.00,
            fuelAverageCost: 0.00,
            totalInventoryPurchase: 0.00,
            fuelPurchase: 0.00,
            totalFuelPurchase: 0.00,
            inventoryPurchase: 0.00,
            totalFuelVolumePurchased: 0.00,
            lotterySales: {},
            salesTax: {},
            deptSales: [],
            otherTaxes: [],
            fuelDelivered: [],
            fuelDeliveredByType: [],
            totalSales: {},
            storeMappings: UserStore.getters.getStoreMappings,
        }
    },

    methods: {

        async fetchData() {

            if (UserStore.getters.getActiveStore) {

               this.isLoading = true
                await this.resetMonthData()
                var startDate = moment(this.monthSelected).startOf('month').format('YYYY-MM-DD')
                var endDate = moment(this.monthSelected).endOf('month').format('YYYY-MM-DD')

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/sales/profit?size=50" + "&fromDate="+startDate+"&toDate="+endDate

                await axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.profitAndLossData = response.data.data
                        this.getDeptMappingsRevenue()
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                        if (err.response.status === 404 || err.response.status === 400) {
                            this.$buefy.toast.open({
                                message: 'No shift data found',
                                type: 'is-warning'
                            })
                        }
                    })
            }
            this.isLoading = false
            
        },

        async fetchMonthlyData() {
            if (UserStore.getters.getActiveStore) {

            this.previousYearMonthlySales = 0
            
            var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                + UserStore.getters.getActiveStore.storeId + '/monthlyAssetReport/'  + moment(this.salesDateRange[1]).format('YYYY-MM-DD')

            await axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken,
                        'correlation-id': this.$uuid.v1()
                    },                   
                })
                .then( (response) => {
                    if (response.data.data.monthlyAssetReportDetailDTOList == null) {
                        response.data.data.monthlyAssetReportDetailDTOList = []
                    }
                    response.data.data.monthlyAssetReportDetailDTOList.forEach( item => {
                        if (item.monthlyAssetReportItemsId === this.previousYearMonthlySalesItemId) {
                            this.previousYearMonthlySales = item.multiplicand
                        }
                    })
                })
                .catch((err) => {
                    this.previousYearMonthlySales = 0
                    if (err.response && (err.response.status === 404 || err.response.status === 400)) {
                        this.$buefy.toast.open({
                            message: 'No Month End data found for ' + moment(this.salesDateRange[0]).format('MMMM YYYY' + err),
                            type: 'is-warning'
                        })
                    }
                })
            }
        },

        async fetchAssetItems() {
            if (UserStore.getters.getActiveStore) {
                if (this.debug) console.log("Fetching AssetItems")
                this.previousYearMonthlySalesItemId = null
                this.previousYearMonthlySales = 0

                var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + '/monthlyAssetReportItems'

                await axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        
                        response.data.data.forEach(asset => {
                            if (asset.name !== "Previous Year Monthly Sales") {
                                return
                            } else {
                                this.previousYearMonthlySalesItemId = asset.id
                            }
                        })

                        if (this.debug) console.log("AssetItem Fetch Success", this.previousYearMonthlySalesItemId)
                    })
                    .catch( (err) => {
                        this.previousYearMonthlySalesItemId = null
                        this.previousYearMonthlySales = 0
                        this.errorCode = err
                        if (this.debug) console.log("AssetReport Fetch Failed", this.errorCode)
                        
                    })
            }

        },

        async fetchPrevYearData() {

            if (UserStore.getters.getActiveStore) {
                var prevStartDate = moment(this.monthSelected).subtract(1,'years').startOf('month').format('YYYY-MM-DD')
                var prevEndDate = moment(this.monthSelected).subtract(1,'years').endOf('month').format('YYYY-MM-DD')
                this.isLoading = true
                this.resetPrevMonthData()

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/sales/profit?size=50" + "&fromDate="+prevStartDate+"&toDate="+prevEndDate

                await axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.prevYearProfitAndLossData = response.data.data
                        this.addPrevToGroup()
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                        if (err.response.status === 404 || err.response.status === 400) {
                            this.$buefy.toast.open({
                                message: 'No shift data found',
                                type: 'is-warning'
                            })
                        }
                    })
            }
            this.isLoading = false

        },

        async getDeptMappingsRevenue() {

            this.deptMappingsRevenue = this.profitAndLossData.deptRevenueAndCostList.filter((dept) => {
                return dept.storeMapping !== 'Taxes'
            })

            this.deptMappingsRevenue.forEach(mapping => {
                if (mapping.storeMapping === "Taxes" || 
                    mapping.storeMapping === "Fuel" || 
                    mapping.storeMapping === "Lottery" ||
                    mapping.storeMapping === "Cigarette" || 
                    mapping.storeMapping === "CigaretteCarton10" ||  
                    mapping.storeMapping === "CarWash" 
                    ) {
                    return
                }
                if (mapping.storeMapping == null | mapping.storeMapping == "") {
                    mapping.storeMapping = "Other"
                }
                var map = this.deptSales.find((map) => map.storeMapping === mapping.storeMapping)
                if (map) {
                        map.netSalesValue += mapping.netSalesValue,
                        map.cost += mapping.cost,
                        map.profit += mapping.profit 
                } else {
                    this.deptSales.push({
                        "storeMapping": mapping.storeMapping,
                        "netSalesValue": mapping.netSalesValue,
                        "cost": mapping.cost,
                        "profit": mapping.profit,
                        "prevYearSales": 0.00,
                        "prevCost": 0.00,
                        "prevProfit": 0.00,
                        "prevPercentage": 0.00,                 
                        })
                }

                this.totalMerchandiseRevenue += mapping.netSalesValue
                this.totalMerchandiseCost += mapping.cost
                this.totalMerchandiseProfit += mapping.profit
            })
            
            this.totalGrossRevenue =  this.totalMerchandiseRevenue 

        },

        async resetMonthData() {
            this.deptMappingsRevenue = []
            this.otherTaxes = []
            this.deptSales = []
            this.profitAndLossData = []
            this.lotterySales = {}
            this.salesTax = {}
            this.totalFuelRevenue = 0.00
            this.totalMerchandiseRevenue = 0.00
            this.prevYearProfitAndLossData = {totalFuelVolumePurchased: 0.00}
            this.totalPrevGrossRevenue = 0.00
            this.totalPrevMerchandiseRevenue = 0.00
            this.prevYearDate = moment(this.monthSelected).subtract(1, 'years').startOf('month').toDate(),
            this.prevDeptMappingsRevenue = 0.00
            this.totalGrossRevenue = 0.00
            this.totalFuelCost = 0.00
            this.totalFuelProfit = 0.00
            this.totalFuelVolume = 0.00
            this.totalMerchandiseCost = 0.00
            this.totalMerchandiseProfit = 0.00
            this.totalCostOfGoodsSold = 0.00
            this.totalProfit = 0.00
            this.totalOperatingExpense = 0.00
            this.totalOtherIncome = 0.00
            this.fuelProfitPerGallon = 0.00
            this.fuelAverageCost = 0.00
            this.totalInventoryPurchase = 0.00
            this.fuelPurchase = 0.00
            this.totalFuelPurchase = 0.00
            this.inventoryPurchase = 0.00
            this.totalFuelVolumePurchased = 0.00
        },

        async resetPrevMonthData() {

        },

        async addPrevToGroup() {

            this.prevDeptMappingsRevenue = this.prevYearProfitAndLossData.deptRevenueAndCostList.filter((prevDept) => {
                return prevDept.storeMapping !== 'Taxes'
            })

            this.totalPrevMerchandiseRevenue = 0.00
            this.totalPrevGrossRevenue = 0.00

            this.prevDeptMappingsRevenue.forEach((prevDept) => {
                if (prevDept.storeMapping === "Taxes" || 
                    prevDept.storeMapping === "Fuel" || 
                    prevDept.storeMapping === "Lottery" ||
                    prevDept.storeMapping === "Cigarette" || 
                    prevDept.storeMapping === "CigaretteCarton10" ||  
                    prevDept.storeMapping === "CarWash" 
                    ) {
                    return
                }

                var dept = this.deptSales.find(o => o.storeMapping === prevDept.storeMapping);
                
                if (dept) {
                    dept.prevYearSales += prevDept.netSalesValue
                    dept.prevCost += prevDept.cost
                    dept.prevProfit += prevDept.profit
                    dept.prevPercentage = prevDept.percentage
                    this.totalPrevGrossRevenue += prevDept.netSalesValue
                }
            })

        },

        togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                this.$refs.pdfReport.loadData()
            }
        },

        setDateRange(dateRange) {
            this.salesDateRange = [dateRange[0], dateRange[1]]
        },

        getDeptMappingName(deptMapping) {
            var deptMap = this.storeMappings.find(item => item.value === deptMapping)
            if (deptMap) {
                return deptMap.name
            }
            return ""
        },

        monthFormatter(date) {
            return moment(date).format('MMMM YYYY')
        },
        
    },

    watch: {
        salesDateRange: async function() {
            await this.fetchData()
            if (this.previousYearMonthlySalesItemId) {
                await this.fetchMonthlyData() 
            }
            if (this.previousYearMonthlySales == 0) {
                await this.fetchPrevYearData()
            }
        },

        monthSelected: async function() {
            this.startDate = moment(this.monthSelected).startOf('month').toDate()
            this.endDate = moment(this.monthSelected).endOf('month').toDate(),
            this.salesDateRange=[this.startDate, this.endDate]
        }

    },    

    computed: {

        storeSalesCommission() {
            return this.settingsValues.commissionStorePercentage.value * this.totalMerchandiseRevenue / 100
        },

        totalSalesIncrease() {
            if (this.previousYearMonthlySales != 0) {
                return (this.totalMerchandiseRevenue - this.previousYearMonthlySales)
            } else {
                if (this.totalPrevGrossRevenue == 0.00) {
                    return 0.00
                } else {
                    return (this.totalMerchandiseRevenue - this.totalPrevGrossRevenue)
                }
            }
        },

        salesIncreaseCommission() {
            var commission = (this.totalSalesIncrease * this.settingsValues.commissionSalesIncreasePercentage.value) / 100
            if (commission < 0) {
                commission = 0
            }
            return commission
        },

        totalStoreCommission() {
            return this.storeSalesCommission + this.salesIncreaseCommission
        },

        managerCommission() {
            return (this.settingsValues.commissionManagerPercentage.value * this.totalStoreCommission / 100)
        },

        assistantManagerCommission() {
            return (this.settingsValues.commissionAssistantManagerPercentage.value * this.totalStoreCommission / 100)
        },

        monthName() {
            return moment(this.month).format('MMMM')
        },

        getActiveStore() {

            return UserStore.getters.getActiveStore
        },

        totalFuelVolumeDelivered() {

            return this.fuelDeliveredByType.reduce((a, b) => {
                return a + b["deliverdVol"];
            }, 0);

        },

        totalInventoryPurchased() {
            
            if (this.inventoryPurchase.length > 0) {
                return this.inventoryPurchase.reduce((a, b) => {
                            return a + b["expenseCost"];
                        }, 0);            
            } else {
                return 0
            }
        },
        
        totalNonTaxableSales() {
            var totalTaxableSales = this.profitAndLossData.taxInfoList.reduce((a, b) => {
                return a + b["taxableSales"] + b["taxableRefunds"];
            }, 0);
            return this.totalMerchandiseRevenue - totalTaxableSales
        },

        netLottery() {
            return (this.lotterySales.onlineLotterySales ? this.lotterySales.onlineLotterySales : 0)
                - (this.lotterySales.onlineLotteryCanceled ? this.lotterySales.onlineLotteryCanceled : 0)
                - (this.lotterySales.onlineLotteryPaidOut ? this.lotterySales.onlineLotteryPaidOut : 0)
                + (this.lotterySales.instantLotterySales ? this.lotterySales.instantLotterySales : 0)
                - (this.lotterySales.instantLotteryPaidOut ? this.lotterySales.instantLotteryPaidOut : 0)
        }

    },

    mounted() {
        if (! this.getSettingUserAuthorized('commissionAuthorizedEmails')) {
            return
        }
        this.fetchAssetItems()
        // this.startDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), 1)
        this.startDate = moment(this.monthSelected).startOf('month').toDate()
        this.endDate = moment(this.monthSelected).endOf('month').toDate(),
        this.salesDateRange=[this.startDate, this.endDate]
        
    }    
}
</script>

<style>

.bottom-bordered-row {
    border-bottom: 0.5px solid gray;
    padding-bottom: 2.5em;
    height: 2em;
}

.bottom-bordered-row2 {
    border-bottom: 0.5px solid gray;
    padding-bottom: 2.5em;

}

.total-row {
    background-color:  rgb(230, 230, 230);
    font-weight: 1000;
}

.month-date-selector {
    color: brown;
    font-weight: 900;
    font-size-adjust: 0.6;
}
</style>