<template>
<div class="section" v-if="getSetting('showCommission') && getSettingUserAuthorized('commissionAuthorizedEmails')">

    <b-modal v-model="isShowPDFDialogLocal" scroll="keep" @close="closePDFDialog">
        <div class="card popupDialog">
            <div class="card-content">
                    <iframe id="pdf" class="pdfiFrame">
                        Your browser doesn't support iframes
                    </iframe> 
            </div>
        </div>
    </b-modal>        

</div>
<div class="box is-danger" v-else><div class="hero title">Not authorized</div></div>
</template>

<script>

import { PDFDocument, StandardFonts, PageSizes, degrees, grayscale, rgb } from 'pdf-lib'
// import { PageSizes } from 'pdf-lib'
// import { degrees } from 'pdf-lib'
// import { rgb } from 'pdf-lib'
import moment from 'moment'
import UserStore from "../../../store/UserStore"
// import { text } from 'body-parser'

export default {
    props: {
        isShowPDFDialog: Boolean,
        deptSales: [],
        dateRange: [],
        totalPrevGrossRevenue: Number,
        previousYearMonthlySales: Number
    },

    data() {
        return {
            pdfDoc: {},
            helveticaFont: {},
            pageNumber: 0,
            page: {},
            sortedRecords: [],
            isShowPDFDialogLocal: false,
            deptMappingsRevenue: [],
            totalBookValue: 0,
            totalBookCount: 0,
            totalSoldValue: 0,
            totalBalanceValue: 0,
            totalFuelSales: 0,
            totalMerchSales: 0,
            totalOtherIncome: 0,
            totalRevenue: 0,
            totalFuelCost: 0,
            totalMerchCost: 0,
            totalCostOfGoodsSold: 0,
            otherIncomeGroupedByVendor: [],
            totalSaleVolume: 0,
            fuelInventoryPurchase: 0,
            dollarUSLocale: Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD'}),
            settingsValues: UserStore.getters.getStoreSettingsValues,
        }

    },

    methods: {

        closePDFDialog() {
            this.$emit('close-pdf-dialog')
        },

        createPdf: async(gthis) => {

            // if (!this.getSetting('showCommission') &&  !this.getSettingUserAuthorized('settingsAuthorizedEmails')) {
            //     return
            // }

            gthis.pdfDoc = await PDFDocument.create()
            // const pdfDoc = gthis.pdfDoc
            gthis.pdfDoc.setAuthor('VEECLi Inc. All rights reserved.')
            gthis.pdfDoc.setTitle('Commission Report')
            gthis.pdfDoc.setSubject('support@veecli.com')
            gthis.pdfDoc.setCreator('VEECLi Inc - www.veecli.com')
            gthis.pdfDoc.setProducer('VEECLi Backoffice Software')

            gthis.helveticaFont = await gthis.pdfDoc.embedFont(StandardFonts.Helvetica)

            await gthis.pageHeader(gthis)
            // var text = ""
            var row = 680


            // Merchandise Sales
            row = await gthis.getNextRow(gthis,row, 6)
            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'MERCHANDISE SALES')

            row = await gthis.getNextRow(gthis,row)
            gthis.drawColHeadRight(gthis, row, 500, 9, "Sales Amount")
            var totalMerchSales = 0.00
            for (const dept of gthis.sortArrayByProp(gthis.deptSales, 'storeMapping', 'asc')) {
                
                row = await gthis.drawRowTextLeft(gthis, row, dept.storeMapping[0].toUpperCase() + dept.storeMapping.substr(1).toLowerCase())
                gthis.drawTextRightCurrency(gthis, row, 500, 10, dept.netSalesValue)
                totalMerchSales += dept.netSalesValue
                row = await gthis.drawLine(gthis, row)
            }

            row = await gthis.getNextRow(gthis,row, 3)

            gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'TOTAL MERCHANDISE SALES')

            gthis.drawTextRightCurrency(gthis, row, 570, 10, totalMerchSales)
            gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)
            // End Merchandise Sales

            // Sales Commission
            // row = await gthis.getNextRow(gthis,row, 6)
            // gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'SALES COMMISSION')

            // row = await gthis.getNextRow(gthis,row)
            // gthis.drawColHeadRight(gthis, row, 375, 9, "Commission %")
            // gthis.drawColHeadRight(gthis, row, 500, 9, "Commission Amount")

            var text = ""
            // row = await gthis.drawLine(gthis, row)

            var storeCommissionAmount = gthis.settingsValues.commissionStorePercentage.value * totalMerchSales / 100
            var commPerc = (gthis.settingsValues.commissionManagerPercentage.value * storeCommissionAmount / totalMerchSales)

            row = await gthis.getNextRow(gthis,row, 6)

            gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'Store Commission')
            gthis.drawTextRight(gthis, row, 380, 10, gthis.settingsValues.commissionStorePercentage.value + " %")
            gthis.drawTextRightCurrency(gthis, row, 570, 10, storeCommissionAmount)
            gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)
            // End Sales Commission

            // Previous Year Merchandise Sales

            var totalPrevMerchSales = 0.00
            if (gthis.previousYearMonthlySales == 0 && gthis.totalPrevGrossRevenue > 0.00) {
                row = await gthis.getNextRow(gthis,row, 6)
                gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'PREVIOUS YEAR MERCHANDISE SALES')

                row = await gthis.getNextRow(gthis,row)
                gthis.drawColHeadRight(gthis, row, 500, 9, "Sales Amount")
                for (const dept of gthis.sortArrayByProp(gthis.deptSales, 'storeMapping', 'asc')) {
                    
                    row = await gthis.drawRowTextLeft(gthis, row, dept.storeMapping[0].toUpperCase() + dept.storeMapping.substr(1).toLowerCase())
                    gthis.drawTextRightCurrency(gthis, row, 500, 10, dept.prevYearSales)
                    totalPrevMerchSales += dept.prevYearSales

                    row = await gthis.drawLine(gthis, row)
                    
                }
            } else if (gthis.previousYearMonthlySales > 0) {
                row = await gthis.getNextRow(gthis,row, 6)
                gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'PREVIOUS YEAR MERCHANDISE SALES')
                totalPrevMerchSales = gthis.previousYearMonthlySales
                row = await gthis.getNextRow(gthis,row, 3)
            } else {
                row = await gthis.getNextRow(gthis,row, 6)
                gthis.drawRowHeadTextLeft(gthis, row, 30, 10, '*** PREVIOUS YEAR SALES NOT AVAILABLE ***')
                totalPrevMerchSales = 0
                row = await gthis.getNextRow(gthis,row, 3)

            }
            
            var salesIncrease = 0
            if (totalPrevMerchSales > 0) {
                salesIncrease = totalMerchSales - totalPrevMerchSales
            }

            if (totalPrevMerchSales > 0) {
                row = await gthis.getNextRow(gthis,row, 6)
                gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'TOTAL PREVOUS YEAR MERCHANDISE SALES')

                gthis.drawTextRightCurrency(gthis, row, 570, 10, totalPrevMerchSales)
                gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)


                row = await gthis.getNextRow(gthis,row, 6)

                if (totalMerchSales - totalPrevMerchSales > 0) {
                    gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'Sales Increase')
                } else {
                    gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'Sales Decrease')
                }
                
                gthis.drawTextRight(gthis, row, 380, 10, ((salesIncrease / totalMerchSales) * 100).toFixed(2)+ " %")
                gthis.drawTextRightCurrency(gthis, row, 500, 10, salesIncrease)
            }
 
            // gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)
            // End Previous Year Merchandise Sales
            
            // Sales Increase Commission
            // row = await gthis.getNextRow(gthis,row, 6)
            // gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'SALES INCREASE COMMISSION')
            row = await gthis.getNextRow(gthis,row, 2)
            row = await gthis.drawRowTextLeft(gthis, row, "Store Bonus Commission")
            commPerc = gthis.settingsValues.commissionSalesIncreasePercentage.value.toFixed(2)
            gthis.drawTextRight(gthis, row, 380, 10, commPerc + " %")
            if (totalMerchSales - totalPrevMerchSales < 0.00) {
                text = "0.00"
            } else {
                text = (gthis.settingsValues.commissionSalesIncreasePercentage.value * salesIncrease / 100)
            }
            gthis.drawTextRightCurrency(gthis, row, 570, 10, text)
            gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)
            row = await gthis.drawLine(gthis, row)

            // End Sales Increase Commission

            row = await gthis.getNextRow(gthis,row, 6)
            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'COMMISSION SUMMARY')

            // Store Commission
            row = await gthis.getNextRow(gthis,row, 3)
            gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'Store Commission')
            gthis.drawTextRight(gthis, row, 380, 10, gthis.settingsValues.commissionStorePercentage.value + " %")
            gthis.drawTextRightCurrency(gthis, row, 500, 10, storeCommissionAmount)
            // gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)


            // Sales Increase Commission
            row = await gthis.getNextRow(gthis,row, 2)
            var totalSalesIncreaseCommission = 0
            row = await gthis.drawRowTextLeft(gthis, row, "Store Bonus Commission")
            commPerc = gthis.settingsValues.commissionSalesIncreasePercentage.value.toFixed(2)
            gthis.drawTextRight(gthis, row, 380, 10, commPerc + " %")
            if (totalMerchSales - totalPrevMerchSales > 0.00) {
                totalSalesIncreaseCommission = (gthis.settingsValues.commissionSalesIncreasePercentage.value * salesIncrease / 100)
            }
            gthis.drawTextRightCurrency(gthis, row, 500, 10, totalSalesIncreaseCommission)
            row = await gthis.drawLine(gthis, row)


            // Total Commission
            row = await gthis.getNextRow(gthis,row, 6)
            gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'TOTAL COMMISSION')
            var totalCommission = totalSalesIncreaseCommission + storeCommissionAmount
            gthis.drawTextRightCurrency(gthis, row, 570, 10, totalCommission)
            gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)

            // Manager Commission
            row = await gthis.getNextRow(gthis,row, 6)
            row = await gthis.drawRowTextLeft(gthis, row, "Manager Commission")
            commPerc = gthis.settingsValues.commissionManagerPercentage.value
            gthis.drawTextRight(gthis, row, 380, 10, commPerc + " %")
            text = (gthis.settingsValues.commissionManagerPercentage.value * totalCommission / 100)
            gthis.drawTextRightCurrency(gthis, row, 500, 10, text)
            row = await gthis.drawLine(gthis, row)

            // Assistant Manager Commission
            row = await gthis.drawRowTextLeft(gthis, row, "Assistant Manager Commission")
            commPerc = gthis.settingsValues.commissionAssistantManagerPercentage.value 
            gthis.drawTextRight(gthis, row, 380, 10, commPerc + " %")
            text = (gthis.settingsValues.commissionAssistantManagerPercentage.value * totalCommission / 100)
            gthis.drawTextRightCurrency(gthis, row, 500, 10, text)
            row = await gthis.drawLine(gthis, row)



                // } else {
            //     row = await gthis.getNextRow(gthis,row, 6)
            //     gthis.drawRowHeadTextLeft(gthis, row, 30, 10, '*** PREVIOUS YEAR DATA NOT AVAILABLE ***')
            // }



            // Create PDF Document
            const pdfDataUri = await gthis.pdfDoc.saveAsBase64({ dataUri: true });
            document.getElementById('pdf').src = pdfDataUri;
        },

        pageHeader: async(gthis, titleText)  => {

            if (!titleText) {
                titleText = "COMMISSION REPORT"
            }
            gthis.pageNumber++

            gthis.page = gthis.pdfDoc.addPage(PageSizes.Letter);
            
            var storeName = UserStore.getters.getActiveStore.storeName
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(storeName, 14);

            gthis.page.drawText(storeName, { 
                x: 300 - (textWidth/2), 
                y: 750, 
                size: 14, 
                })

            var row = 725
            var reportHead = titleText
            textWidth = gthis.helveticaFont.widthOfTextAtSize(reportHead, 13);

            gthis.page.drawText(reportHead, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 15, 
                })            

            row = 700
            var dateRange = moment(gthis.dateRange[0]).format('MM/DD/YYYY') + " - " + moment(gthis.dateRange[1]).format('MM/DD/YYYY')
            textWidth = gthis.helveticaFont.widthOfTextAtSize(dateRange, 11);

            gthis.page.drawText(dateRange, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 11, 
                })
            await gthis.pageFooter(gthis)                         
                
        },

        veecliLogo: async(gthis) => {
            const pngUrl = '/images/veecli-logo.png'
            const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer())

            const pngImage = await gthis.pdfDoc.embedPng(pngImageBytes)
            const pngDims = pngImage.scale(0.08)
            
            gthis.page.drawImage(pngImage, {
                x: 300,
                y: 18,
                width: pngDims.width,
                height: pngDims.height,
                rotate: degrees(0),
                opacity: 0.75,
            })
            gthis.page.drawText(" VEECLi.com", { 
                x: 308, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })                    
        },

        pageFooter: async(gthis) => {

            let timeNow = Date()
            timeNow = moment(timeNow).format('MM/DD/YYYY hh:mm a')

            gthis.page.drawText(timeNow, { 
                x: 20, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })

            var textWidth = gthis.helveticaFont.widthOfTextAtSize('Page ' + gthis.pageNumber, 9)
            gthis.page.drawText('Page ' + gthis.pageNumber, { 
                x: 590 - textWidth, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })
            await gthis.veecliLogo(gthis)
        },

        getNextRow: async(gthis,row, count) => {
  
            if (!count) count = 1
            if (row > 75) {
                return row - (5 * count)
            } else {
                await gthis.pageHeader(gthis)

                // Set Line to start records
                return 680
            }
            
        },

        drawRowHeadTextLeft: async (gthis, row, colStart, fontSize, text) => {
            gthis.page.drawText(text, { 
                x: colStart, 
                y: row, 
                size: fontSize, 
            })
            return row
        },

        drawRowTextLeft: async (gthis, row, text) => {
            row = await gthis.getNextRow(gthis,row, 2)
            // text = text[0].toUpperCase() + text.substr(1).toLowerCase()
            if (text === "InsideSales") { text = "Inside Sales"}
            if (text === "BeerWine") { text = "Beer / Wine"}
            text = gthis.toTitleCase(text)

            gthis.page.drawText(text, { 
                x: 45, 
                y: row, 
                size: 9, 
            })
            return row
        },

        drawTextLeft: async (gthis, row, colStart, fontSize, text) => {
            gthis.page.drawText(text, { 
                x: colStart, 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawTextRight: async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
            gthis.page.drawText(text, { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawColHeadRight:async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
            gthis.page.drawText(text, { 
                x: colStart * 1 - (textWidth), 
                y: row+3, 
                size: fontSize,
                TextAlignment: 2 
            })
            gthis.page.drawLine({
                start: { x: colStart - textWidth - 40, y: row },
                end: { x: colStart, y: row  },
                thickness: 1,
                opacity: 0.4,
            })
            
        },

        drawTextRightGal: async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text+" gal.", fontSize)
            gthis.page.drawText(text+" gal.", { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawTextRightCurrency: async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(gthis.dollarUSLocale.format(text), fontSize)
            gthis.page.drawText(gthis.dollarUSLocale.format(text), { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },        

        drawLine: async (gthis, row) => {
            row = await gthis.getNextRow(gthis,row, 1)
            gthis.page.drawLine({
                start: { x: 45, y: row },
                end: { x: 500, y: row },
                thickness: 1,
                opacity: 0.2,
            })
            return row
        },

        drawTotalRectangle: async (gthis, row, colStart, width, height, opacity) => {
            gthis.page.drawRectangle({
                x: colStart-4,
                y: row-4,
                width: width,
                height: height,
                rotate: degrees(0),
                borderWidth: 0,
                borderColor: grayscale(0.5),
                color: rgb(0, 0, 0),
                opacity: opacity,
                borderOpacity: 0.75,
            })
        },

        async loadData() {
            this.isShowPDFDialogLocal = true
            this.createPdf(this)
            this.pageNumber = 0
        },
    },
  
}
</script>


<style scoped>

.popupDialog {
    /* position:absolute; */
    width:100%;
    height: 100%; 
    max-height: 1000px;
    min-height: 900px;     
}

.pdfiFrame {
    position:absolute; 
    top:0; 
    left:0; 
    bottom:0; 
    /* right:0;  */
    width:100%; 
    height:100%; 
    /* border:none; */
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
    /* padding:0;  */
    overflow:hidden; 
    /* z-index:999999;    */
}
div{
    height: 100%;
}
</style>